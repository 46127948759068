import React from 'react'
import TextLoop from 'react-text-loop'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import animationData from '../lottie/iso1.json'

const Text = styled.span`
  font-size: 5vw;
  text-align: center;
  font-weight: bold;

  @media (min-width: 992px) {
    font-size: 36px;
  }
`

const Slider = () => {
  return (
    <section className="hero">
      <div className="hero-body">
        <div className="container" style={{paddingTop: '50px'}}>
          <div className="columns">
            <div
              className="column"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                paddingBottom:'-200px'
              }}
            >
              <div>
                <TextLoop
                  // springConfig={{ stiffness: 140, damping: 8 }}
                  interval={3000}
                >
                  <Text>Desarrollos personalizados</Text>
                  <Text>Aplicaciones multiplataforma</Text>
                  <Text>Tiendas e-commerce</Text>
                  <Text>Consultoría</Text>
                  <Text>Software Seguro</Text>
                  <Text>Páginas web</Text>
                </TextLoop>
              </div>
            </div>
            <div className="column">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height="80%"
                width="80%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Slider
