import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styled from 'styled-components'
import {
  FaCode,
  FaShoppingCart,
  FaMobileAlt,
  FaWrench,
  FaHandshake,
  FaSearch,
  FaDraftingCompass,
  FaDesktop,
} from 'react-icons/fa'
import Slider from '../components/Slider'

// import BackgroundImage from 'gatsby-background-image'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

const Separator = styled.div`
  border-color: #fe1d03;
  border-top-width: 4px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 100px;

  border-bottom-style: solid;
  border-top-style: solid;

  height: auto;
  border-bottom-width: 0;
`

const CircleIcon = styled.div`
  -webkit-animation-duration: 350ms;
  animation-duration: 350ms;

  height: 102px;
  width: 102px;
  line-height: 57px;
  border-color: #fe1d03;
  border-width: 2px;
  border-style: solid;
  background-color: #fe1d03;
  margin-right: 20px;
  box-sizing: content-box;
  border-radius: 50%;

  border-color: #fe1d03;
  border-width: 6px;
  background-color: #fe1d03;
  box-sizing: content-box;
  height: 90px;
  width: 90px;
  line-height: 90px;
  border-radius: 50%;
  position: relative;
  top: auto;
  left: auto;
  margin: 0 auto;
  margin-bottom: 15px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 45px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Icon = styled.div`
  font-size: 36px;
  color: #fe1d03;
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Inicio"
        keywords={[`ember`, `apps`, `páginas web`, `aplicaciones`]}
      />
      <Slider />
      <div className="section" style={{paddingTop: '0px'}}>
        <div className="container">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              <div className="has-text-centered">
                <span className="is-size-1 has-text-weight-bold">
                  ¿Qué es lo que hacemos?
                </span>
                <Separator />
                <p className="has-text-justified" style={{ fontSize: '23px', marginTop: '-35px'}}>
                  Somos una agencia de desarrollo de software apasionados por la
                  innovación y comprometidos en cada uno de nuestros proyectos
                  para cumplir las necesidades de nuestros clientes con un enfoque de seguridad incluido en sus aplicaciones.
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '75px' }} className="columns">
            <div className="column" style={{ fontSize: '22px'}}>
              <Card>
                <Icon>
                  <FaCode />
                </Icon>
                <span className="has-text-centered">
                  <strong>Software a la medida</strong>
                </span>
                <span className="has-text-centered">Crea algo especial para ti</span>
              </Card>
            </div>
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
              <Card>
                <Icon>
                  <FaShoppingCart />
                </Icon>
                <span>
                  <strong>Tienda en línea</strong>
                </span>
                <span>Comercializa usando internet</span>
              </Card>
            </div>
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
              <Card>
                <Icon>
                  <FaMobileAlt />
                </Icon>
                <span>
                  <strong>Apps móviles</strong>
                </span>
                <span>Llega a los teléfonos desde una aplicación</span>
              </Card>
            </div>
          </div>
          <div style={{ marginTop: '30px' }} className="columns">
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
              <Card>
                <Icon>
                  <FaWrench />
                </Icon>
                <span>
                  <strong>Actualización de software</strong>
                </span>
                <span>Mejora tus procesos desde la base</span>
              </Card>
            </div>
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
              <Card>
                <Icon>
                  <FaDesktop />
                </Icon>
                <span>
                  <strong>Páginas web</strong>
                </span>
                <span>Ten presencia en internet</span>
              </Card>
            </div>
            <div className="column" style={{ fontSize: '22px'}}>
              <Card>
                <Icon>
                  <FaHandshake />
                </Icon>
                <span className="has-text-centered">
                  <strong>Consultoría</strong>
                </span>
                <span className="has-text-centered">Te ayudamos con las mejores opciones personalizadas</span>
              </Card>
            </div>
          </div>

          
          <div style={{ marginTop: '100px' }} className="columns">
            <div className="column is-half is-offset-one-quarter">
              <div className="has-text-centered">
                <span className="is-size-1 has-text-weight-bold">
                  ¿Cómo lo hacemos?
                </span>
                <Separator />
                <p className="has-text-justified" style={{ fontSize: '23px', marginTop: '-35px'}}>
                  Seguimos un flujo de ciclo de vida de desarrollo para cada proyecto, en el cual atacamos directamente las necesidades desde su diseño.
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '70px' }} className="columns">
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
              <Card>
                <CircleIcon>
                  <FaSearch />
                </CircleIcon>
                <span>
                  <strong>Analizamos tus necesidades</strong>
                </span>
                <span>
                  Nos apegamos a la capacidad y restricciones sin descuidar el
                  objetivo del software.
                </span>
              </Card>
            </div>
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
              <Card>
                <CircleIcon>
                  <FaDraftingCompass />
                </CircleIcon>
                <span>
                  <strong>Diseñamos una solución</strong>
                </span>
                <span>
                  Te damos la mejor opción para que se cumpla el objetivo del
                  software.
                </span>
              </Card>
            </div>
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
              <Card>
                <CircleIcon>
                  <FaWrench />
                </CircleIcon>
                <span>
                  <strong>¡A desarrollar!</strong>
                </span>
                <span>
                  Una vez aceptada la solución nos ponemos a trabajar para que
                  se haga una realidad.
                </span>
              </Card>
            </div>
          </div>
        </div>


        <div className="columns" style={{paddingTop: '15px'}}>
            <div className="column is-half is-offset-one-quarter">
              <div className="has-text-centered">
                <span className="is-size-1 has-text-weight-bold">
                  Programación segura
                </span>
                <Separator />
                <p className="has-text-justified" style={{ fontSize: '23px', marginTop: '-35px'}}>
                  Seguimos las mejores prácticas de desarrollo de software seguro, así como las guías indicadas y los mejores estándares para que tu aplicación sea segura desde su creación.
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '50px' }} className="columns">
            <div className="column" style={{ fontSize: '22px'}}>
              <Card>
              <Img
              style={{ width: '200px', alignItems: 'center' }}
              fluid={data.iso.childImageSharp.fluid}
              alt="logo iso"
            />
              </Card>
            </div>
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
            <Card>
              <Img
              style={{ width: '270px', alignItems: 'center' }}
              fluid={data.owasp.childImageSharp.fluid}
              alt="logo owasp"
            />
              </Card>
            </div>
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
            <Card>
              <Img
              style={{ width: '170px', alignItems: 'center' }}
              fluid={data.sans.childImageSharp.fluid}
              alt="logo sans"
            />
              </Card>
            </div>
          </div>
          <div style={{ marginTop: '15px' }} className="columns">
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
            <Card>
              <Img
              style={{ width: '250px', alignItems: 'center' }}
              fluid={data.pci.childImageSharp.fluid}
              alt="logo pci"
            />
              </Card>
            </div>
            <div className="column has-text-centered" style={{ fontSize: '22px'}}>
            <Card>
              <Img
              style={{ width: '250px', alignItems: 'center' }}
              fluid={data.nist.childImageSharp.fluid}
              alt="logo nist"
            />
              </Card>
            </div>
            <div className="column" style={{ fontSize: '22px'}}>
              <Card>
              <Img
              style={{ width: '200px', alignItems: 'center' }}
              fluid={data.cert.childImageSharp.fluid}
              alt="logo cert"
            />
              </Card>
            </div>
          </div>

      </div>
      {/* <section className="hero" style={{ height: '600px' }}>
        <BackgroundImage
          style={{ height: '100%' }}
          Tag="div"
          fluid={data.itImage.childImageSharp.fluid}
          backgroundColor={`#040e18`}
        >
          <h1
            style={{ paddingTop: '40px' }}
            className="title is-uppercase is-text-weight-bold has-text-centered"
          >
            LA TECNOLOGÍA NOS ENVUELVE
          </h1>

          <h2 className="subtitle is-text-weight-bold has-text-centered">
            No te quedes atrás
          </h2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link
              className="button is-primary is-rounded is-large"
              to="/contacto"
            >
              <strong>CONTÁCTANOS</strong>
            </Link>
          </div>
        </BackgroundImage>
      </section> */}
      <section className="section">
        <div className="container">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <span className="is-size-1 has-text-weight-bold">
              Clientes satisfechos
            </span>
            <Separator />
          </div>
          <AliceCarousel
            mouseDragEnabled
            autoPlay
            autoPlayInterval={3000}
            dotsDisabled
            buttonsDisabled
            responsive={{
              0: {
                items: 1,
              },
              1024: {
                items: 5,
              },
            }}
          >
            <Img
              style={{ width: '200px' }}
              fluid={data.suma.childImageSharp.fluid}
              alt="logo suma mexico"
            />

            <Img
              style={{ width: '200px' }}
              fluid={data.sre.childImageSharp.fluid}
              alt="logo sre"
            />
            <Img
              style={{ width: '200px' }}
              fluid={data.zepelin.childImageSharp.fluid}
              alt="logo zepelin"
            />
            <Img
              style={{ width: '200px' }}
              fluid={data.taponCorona.childImageSharp.fluid}
              alt="logo tapon corona"
            />
            <Img
              style={{ width: '200px' }}
              fluid={data.weePlay.childImageSharp.fluid}
              alt="logo wee play"
            />

            <Img
              style={{ width: '170px' }}
              fluid={data.mexicanCare.childImageSharp.fluid}
              alt="logo mexican care"
            />

            <Img
              style={{ width: '170px' }}
              fluid={data.markk.childImageSharp.fluid}
              alt="logo markk"
            />

            <Img
              style={{ width: '170px' }}
              fluid={data.mapfreAsistencia.childImageSharp.fluid}
              alt="logo mapfre asistencia"
            />

            <Img
              style={{ width: '170px' }}
              fluid={data.jaacyc.childImageSharp.fluid}
              alt="logo jaacyc"
            />

            <Img
              style={{ width: '170px' }}
              fluid={data.tresrconnect.childImageSharp.fluid}
              alt="logo 3 r connect"
            />

            <Img
              style={{ width: '200px' }}
              fluid={data.hitco.childImageSharp.fluid}
              alt="logo hitco"
            />

            <Img
              style={{ width: '200px' }}
              fluid={data.mufg.childImageSharp.fluid}
              alt="logo mufg"
            />

            <Img
              style={{ width: '200px' }}
              fluid={data.ofidam.childImageSharp.fluid}
              alt="logo mufg"
            />
          </AliceCarousel>
        </div>
      </section>
    </Layout>
  )
}

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    suma: file(relativePath: { eq: "clientes/logo_suma.png" }) {
      ...squareImage
    }

    zepelin: file(relativePath: { eq: "clientes/logo_zepelin.png" }) {
      ...squareImage
    }

    taponCorona: file(relativePath: { eq: "clientes/logoTaponCorona.png" }) {
      ...squareImage
    }

    weePlay: file(relativePath: { eq: "clientes/logo_Weeplay.png" }) {
      ...squareImage
    }

    sre: file(relativePath: { eq: "clientes/logo_sre.png" }) {
      ...squareImage
    }

    mexicanCare: file(relativePath: { eq: "clientes/logo_mexicancare.png" }) {
      ...squareImage
    }

    markk: file(relativePath: { eq: "clientes/logo_markk.png" }) {
      ...squareImage
    }

    mapfreAsistencia: file(
      relativePath: { eq: "clientes/logo_MAPFRE-ASISTENCIA.jpg" }
    ) {
      ...squareImage
    }

    jaacyc: file(relativePath: { eq: "clientes/logo_jaacyc.png" }) {
      ...squareImage
    }

    hitco: file(relativePath: { eq: "clientes/logo_hitco.png" }) {
      ...squareImage
    }

    tresrconnect: file(relativePath: { eq: "clientes/logo_3rconnect.png" }) {
      ...squareImage
    }

    mufg: file(relativePath: { eq: "clientes/logo_mufg.png" }) {
      ...squareImage
    }

    ofidam: file(relativePath: { eq: "clientes/logo_ofidam.png" }) {
      ...squareImage
    }

    iso: file(relativePath: { eq: "seguridad/iso.jpg" }) {
      ...squareImage
    }

    nist: file(relativePath: { eq: "seguridad/nist.png" }) {
      ...squareImage
    }

    owasp: file(relativePath: { eq: "seguridad/owasp.png" }) {
      ...squareImage
    }

    pci: file(relativePath: { eq: "seguridad/pci.png" }) {
      ...squareImage
    }

    sans: file(relativePath: { eq: "seguridad/sans.png" }) {
      ...squareImage
    }

    cert: file(relativePath: { eq: "seguridad/cert.png" }) {
      ...squareImage
    }

    itImage: file(relativePath: { eq: "it-team.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
